import React, { useRef, useState } from 'react'
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);
import {
  mainColor,
  mainDarkColor
} from "../../../Constants"




const ColorVariant = (props) => {
  const {t} = useTranslation();
  const [itemValue, setItemValue] = useState(props.default);


  return (
          <Box 
            onClick={(e) => props.onClick(props.color)}
            sx={{

                height: 40, 
                width: 40, 
                backgroundColor: props.color, 
                margin:1, 
                borderRadius:10, 
                border: props.color != props.selectedColor ?  5: 5, 
                borderColor: props.color != props.selectedColor ?  "#D3D3D3" :mainColor,  
                cursor: 'pointer',
                justifyContent: "center",
                display: "flex" }}>
                {props.name ? <Typography variant='h5' color={mainColor}>&#10004;</Typography> :<></>}
                {props.description ? <Typography variant='h5' color={mainColor}>&#10004;</Typography> :<></>}
                {props.color == props.selectedColor ?  <Typography variant='h5' color={mainColor}>&#10004;</Typography>  :<></>}
          </Box>
  );
};
export default ColorVariant;