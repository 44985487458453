export const modelParams = [
    {
        id: 1,
        name: "Тумба с дверцей",
        params:
        {
            defaultItemWidth: 30,
            defaultItemHeight: 53,
            defaultItemDepth: 25,
            minItemDepth: 18,
            maxItemDepth: 50,
            minItemWidth: 25,
            maxItemWidth: 60,
            minItemHeight: 30,
            maxItemHeight: 100,
            defaultItemPrice: 12000,
            priceCoef: 500,
            minPrice: 9500,
            colorsBody:["#f5f5dc", "#b7d3ed", "#494d4e", "#FFD966", "#38761d", "#ffd9df"],
            colorsDoor:["#FEFAF0", "#FFFFFF", "#000000"],
            defaultItemColor: "#b7d3ed",
            defaultItemDoorColor: "#FEFAF0",
        }
    }
]