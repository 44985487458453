import React, { useRef, useState } from 'react'
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);
import {
  mainColor,
  mainDarkColor,
  textColor,
  lightTextColor
} from "../../../Constants"


const OrderValue = (props) => {
  const {t} = useTranslation();

  return (
          <Box my={2}>
              <Stack direction="column">
                {props.description ? <Typography variant='caption'  sx={{fontFamily: 'Manrope', display: 'block' }} color={lightTextColor} gutterBottom>{props.description}</Typography> :<></>}
                {(props.value && props.isColor!=true) ? <Typography variant="body1"  sx={{fontFamily: 'Manrope'}} bold color={textColor}>{props.value}</Typography> :<></>}  
                {props.isColor == true ? <Box sx={{width: "100%", height: "50px", backgroundColor: props.value, borderRadius: 5}}></Box> :<></>}  
              </Stack>
          </Box>
  );
};
export default OrderValue;