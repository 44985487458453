import {modelParams} from "./modelParams"

export const calculatePrice = (selectedProduct, itemDepth, itemWidth, itemHeight) => 
{
        var itemPriceCalc = modelParams.filter(p => p.id == selectedProduct)[0].params.defaultItemPrice;
        var addPrice = 
          (itemDepth-modelParams.filter(p => p.id == selectedProduct)[0].params.defaultItemDepth)* modelParams.filter(p => p.id == selectedProduct)[0].params.priceCoef +
          (itemWidth-modelParams.filter(p => p.id == selectedProduct)[0].params.defaultItemWidth)*modelParams.filter(p => p.id == selectedProduct)[0].params.priceCoef +
          (itemHeight-modelParams.filter(p => p.id == selectedProduct)[0].params.defaultItemHeight)*modelParams.filter(p => p.id == selectedProduct)[0].params.priceCoef
        var itemPriceCalc = itemPriceCalc + addPrice;
    
        if (itemPriceCalc <  modelParams.filter(p => p.id == selectedProduct)[0].params.minPrice)
          itemPriceCalc= modelParams.filter(p => p.id == selectedProduct)[0].params.minPrice;
        return(Math.round(itemPriceCalc,0));
}
