import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import { FiAward , FiCalendar , FiMap, FiImage, FiCoffee, FiSmartphone, FiThumbsUp, FiBarChart ,FiMapPin,  FiHeart ,FiGift, FiChevronUp, FiTrash } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);

const ServiceList = [
    {
        icon: <FiCalendar />,
        title: 'Проектный',
        description: 'Как и обычный, Цифровой субботник характеризуется ограниченными временем и географией проведения'
    },
    {
        icon: <FiSmartphone />,
        title: 'Цифровой',
        description: 'Все задания и экоактивности в рамках Цифрового субботника пользователи осуществляют с помощью приложения TrashBack'
    },
    {
        icon: <FiAward />,
        title: 'Мотивирующий',
        description: 'Помимо нематериальной мотивации и игровой формы, за выполнение каждого действия, пользователи получают материальное вознаграждение в виде Экойнов'
    },
    {
        icon: <FiGift />,
        title: 'Яркий',
        description: 'Сотрудники и члены их семей могут совершать экодействия в любое удобное им время и в удобном месте. Фиксировать результат можно в приложении и в соц сетях'
    },
    {
        icon: <FiBarChart />,
        title: 'Командообразующий',
        description: 'Работодатель получает широкий охват вовлеченных и лояльных сотрудников и их семей, оцифрованный результат по выполнению необходимых заданий , кратный рост объемов вторсырья, направляемых на переработку'
    },
    {
        icon: <FiHeart />,
        title: 'Социальный',
        description: 'Цифровой субботник позволяет кратно увеличить рост объемов вторсырья, направляемых на переработку и выполнить ESG цели компании'
    }
]

const ActionList = [
    {
        icon: <FiMap />,
        title: 'Убирать парки от мусора',
        description: 'В рамках прогулки, пробежки, или целенаправленно пользователь собирает брошенный мусор в лесу или парке. С помощью приложения Вы фискирует факт уборки. Наша команда проверяет пары фотографий, после чего начисляет за собранную упаковку бонусные Экойны'
    },
    {
        icon: <FiImage />,
        title: 'Обучать нейронную сеть',
        description: 'Пользоватеь делаете фотографию использованной упаковки  и размечает ее с помощью ограничивающего прямоугольника. Мы проверяем разметку и используем данные для обучения нейронной сети автоматически распознавать типы вторсырья.'
    },
    {
        icon: <FiMapPin />,
        title: 'Добавить пункт на карту',
        description: 'Участник отмечает на карте пункты приема отходов. Мы добавляем эти данные на карту, чтобы другие жители города знали эту информацию и могли сдавать туда отходы на переработку. '
    },
    {
        icon: <FiThumbsUp />,
        title: 'Сортировать отходы дома',
        description: 'Пользователь устанавливает дома второе ведро для перерабатываемых отходов и фиксирует это важное событие с помощью мобильного приложения. Мы проверяем эти фотографии и выплачиваем Экойны'
    },
    {
        icon: <FiCoffee />,
        title: 'Перейти на многоразовое',
        description: 'Участник приобретает многоразовый кофейный стакан и фиксирует это событие с помощью мобильного приложения. '
    },
    {
        icon: <FiTrash />,
        title: 'Сдавать отходы ',
        description: 'Пользователь сдаети пэт-бутылки и алюминиевые банки в пункт приема отходов TrashBack, фиксирует сдачу вторсырья с помощью мобильного приложения'
    }
]

class NeuralNetwork extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet 
                  pageTitle={t('rvm.pageTitle')}
                  description={t('rvm.description')}
                  keywords={t('rvm.keywords')}
                  image={t('rvm.image')}
                  />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Фандоматы'}   />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Эффективное командообразование и мотивация сотрудников</h2>
                                    <p>Цифровой субботник это набор мероприятий, направленный на вовлечение работников и их семей в раздельный сбор отходов, использование инфраструктуры РСО, уборку территорий и здоровый образ жизни</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>

                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Что можно делать на Цифровом субботнике?</h2>
                                    <p>При регистрации в приложении сотрудник указывает код работодателя в соответствующем поле и ему открываются расширенные возможности сервиса TrashBack</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ActionList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" >
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}


             
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default NeuralNetwork;