import React, { useRef, useState } from 'react'
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);
import { createRoot } from 'react-dom/client'
import * as THREE from 'three';
import { Canvas, useFrame } from '@react-three/fiber'

export const LockerGeometry = (props) => {
const meshRef = useRef()
const [hovered, setHover] = useState(false)
const [depth1, setDepth1] = useState(1)
const [active, setActive] = useState(false)
const x = 0;
const y = 0;
const radius = 5;
const layerWidth = 3;

// useFrame((state, delta) => (meshRef.current.rotation.x += delta))

const extrudeSettings = {
	// steps: 2,
	depth: 1,
	// bevelEnabled: true,
	// bevelThickness: 1,
	// bevelSize: 1,
	// bevelOffset: 0,
	// bevelSegments: 1
};

return (
  <group>
  <mesh
    {...props}
    ref={meshRef}
    scale={10}
    >
    <extrudeGeometry args={[new THREE.Shape()
          .moveTo(x, y + radius)
          .lineTo(x, y + props.itemHeight - radius)
          .quadraticCurveTo(x, y + props.itemHeight, x + radius, y + props.itemHeight)
          .lineTo(x + props.itemWidth - radius, y + props.itemHeight)
          .quadraticCurveTo(x + props.itemWidth, y + props.itemHeight, x + props.itemWidth, y + props.itemHeight - radius)
          .lineTo(x + props.itemWidth, y + radius)
          .quadraticCurveTo(x + props.itemWidth, y, x + props.itemWidth - radius, y)
          .lineTo(x + radius, y)
          .quadraticCurveTo(x, y, x, y + radius), 
      {
        depth: props.itemDepth,
        bevelSize: 0
      }]}/>
    <meshStandardMaterial color={props.itemColor} />
  </mesh>
  <mesh
    {...props}
    ref={meshRef}
    scale={10}
    >
    <extrudeGeometry args={[new THREE.Shape()
          .moveTo(x+layerWidth, y + radius)
          .lineTo(x+layerWidth, y + props.itemHeight - radius - layerWidth)
          .quadraticCurveTo(x+layerWidth, y + props.itemHeight - layerWidth, x + radius , y + props.itemHeight - layerWidth)
          .lineTo(x + props.itemWidth - radius , y + props.itemHeight - layerWidth)
          .quadraticCurveTo(x + props.itemWidth - layerWidth, y + props.itemHeight- layerWidth, x + props.itemWidth - layerWidth, y + props.itemHeight - radius)
          .lineTo(x + props.itemWidth - layerWidth, y + radius)
          .quadraticCurveTo(x + props.itemWidth -layerWidth, y + layerWidth, x + props.itemWidth - radius - layerWidth, y +layerWidth)
          .lineTo(x + radius +layerWidth, y +layerWidth)
          .quadraticCurveTo(x +layerWidth, y + layerWidth, x +layerWidth, y + radius), 
      {
        depth: props.itemDepth+0.1,
        bevelSize: 0
      }]}/>
    <meshStandardMaterial color={props.itemDoorColor} />
  </mesh>
  </group>
)
};