import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BrandTwo from "../elements/BrandTwo";
import PageHelmet from "../component/common/Helmet";
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);
import { useTranslation, withTranslation, Trans } from 'react-i18next';

class MainPage extends Component{
    render(){
      const { t } = this.props;
        return (
          <Fragment>
                            <PageHelmet 
                  pageTitle={t('home.pageTitle')}
                  canonical={t('home.canonical')}
                  description={t('home.description')}
                  keywords={t('home.keywords')}
                  image={t('home.image')}
                  />

            <Header
              headertransparent="header--transparent"
              colorblack="color--black"
              logoname="logo.png"
            />

            {/* Start Slider Area   */}
            <div className="slider-wrapper">
              <SliderOne />
            </div>
            {/* End Slider Area   */}

            {/* Start About Area */}
            <div className="about-area about-position-top pb--120">
              <About />
            </div>
            {/* End About Area */}

            <div className="service-area ptb--80" style={{backgroundColor: "#a6b2bc"}}>
              <div className="container">
                <ServiceTwo />
              </div>
            </div>

            {/* Start Portfolio Area */}
            <div className="portfolio-area ptb--120 bg_color--1">
              <div className="portfolio-sacousel-inner mb--55">
                <Portfolio />
              </div>
            </div>
            {/* End Portfolio Area */}


            {/* Start Testimonial Area */}
            <div className="rn-testimonial-area bg_color--5 ptb--120">
              <div className="container">
                <Testimonial />
              </div>
            </div>
            {/* End Testimonial Area */}

            

            {/* Start Brand Area */}
            <div className="rn-brand-area brand-separation bg_color--5 pb--120">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <BrandTwo />
                  </div>
                </div>
              </div>
            </div>
            {/* End Brand Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
              <ScrollToTop showUnder={160}>
                <FiChevronUp />
              </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />
          </Fragment>
        );
    }
}

export default withTranslation()(MainPage);