import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import { FiAward, FiCheck , FiCalendar , FiMap, FiImage, FiCoffee, FiSmartphone, FiThumbsUp, FiBarChart ,FiMapPin,  FiHeart ,FiGift, FiChevronUp, FiTrash } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import our_printer from "../assets/images/printing/printers.png";
import chair from "../assets/images/printing/3d-printed-furniture.png";
import wpc_3dprint from "../assets/images/portfolio/wpc_3dprint.png";
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);
import { useTranslation, withTranslation, Trans } from 'react-i18next';

class Printing extends Component{
    render(){
      const { t } = this.props;
        return(
            <React.Fragment>
              <PageHelmet 
                  pageTitle={t('printing.pageTitle')}
                  canonical={t('printing.canonical')}
                  description={t('printing.description')}
                  keywords={t('printing.keywords')}
                  image={t('printing.image')}
                  />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={t('printing.title')}   />
                {/* End Breadcrump Area */}

               
                 {/* Start About Area  */}
      <div className="rn-about-area ptb--50 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{t('printing.title1')}</h2>
                    <p className="description">{t('printing.description1')}</p>
                  </div>
                  
                </div>
              </div>

              <div className="col-lg-6 mt_md--40 mt_sm--40">
                <div className="thumbnail">
                  <img className="w-100" src={our_printer} alt="Trashback printer" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}


      <div className="rn-about-area ptb--50 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
            <div className="col-lg-6 mt_md--40 mt_sm--40">
                <div className="thumbnail">
                  <img className="w-100" src={chair} alt="Trashback printer" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{t('printing.title2')}</h2>
                    <p className="description">{t('printing.description2')}</p>
                  </div>
                  
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}


                       {/* Start About Area  */}
                       <div className="rn-about-area ptb--50 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{t('printing.title3')}</h2>
                    <p className="description">{t('printing.description3')}</p>
                  </div>
                  
                </div>
              </div>

              <div className="col-lg-6 mt_md--40 mt_sm--40">
                <div className="thumbnail">
                  <img className="w-100" src={wpc_3dprint} alt="Trashback printer" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default withTranslation()(Printing);