import React, { Component } from "react";


import { useTranslation, withTranslation, Trans } from 'react-i18next';
import {version} from "../../Constants"
import { Helmet, HelmetProvider } from 'react-helmet-async';

const PageHelmet  =(props) => {
        return(
            <React.Fragment>
                <Helmet>
                    <title>{props.pageTitle}</title>
                    <link rel="canonical" href={props.canonical} />
                    <meta name="description" content={props.description}/>
                    <meta name="keywords" content={props.keywords} />
                    <meta name="image" content={props.image} />
                    <meta name="author" content="Trashback" />
                    <meta name="version" content={version} />
                    <meta property="og:title" content={props.pageTitle}/>
                    <meta property="og:image" content={props.image} />
                    <meta property="og:description" content={props.description}/>
                    <meta property="og:type" content="website"/>
                </Helmet>
            </React.Fragment>
        )
}

export default withTranslation()(PageHelmet);
