// React Required
import React, { Component } from "react";
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";

// Create Import File
import "./index.scss";
import './i18n/i18n';


// import PageScrollTop from "./component/PageScrollTop";

// Home layout
import Demo from "./page-demo/Demo";
import MainPage from "./home/MainPage";
import Startup from "./home/Startup";
import Paralax from "./home/Paralax";
import HomePortfolio from "./home/HomePortfolio";
import DigitalAgency from "./home/DigitalAgency";
import CreativeAgency from "./home/CreativeAgency";
import PersonalPortfolio from "./home/PersonalPortfolio";
import Business from "./home/Business";
import StudioAgency from "./home/StudioAgency";
import PortfolioLanding from "./home/PortfolioLanding";
import CreativeLanding from "./home/CreativeLanding";
import HomeParticles from "./home/HomeParticles";
import CreativePortfolio from "./home/CreativePortfolio";
import DesignerPortfolio from "./home/DesignerPortfolio";
import InteriorLanding from "./home/Interior";
import CorporateBusiness from "./home/CorporateBusiness";
import InteractiveAgency from "./home/InteractiveAgency";

// Dark Home Layout
import DarkMainDemo from "./dark/MainDemo";
import DarkPortfolioLanding from "./dark/PortfolioLanding";

// Element Layout
import DigitalSubbotnik from "./elements/DigitalSubbotnik";
import RVM from "./elements/RVM";
import Partners from "./elements/Partners";
import EcoSuperApp from "./elements/EcoSuperApp";
import Printing from "./elements/Printing";
import PrintingStreet from "./elements/PrintingStreet";
import PrintingCustom from "./elements/PrintingCustom";
import AI from "./elements/AI";
import NeuralNetwork from "./elements/NeuralNetwork";

//shop
import Shop from "./elements/shop/Shop";
import Cart from "./elements/shop/Cart";
import CartIcon  from "./elements/shop/CartIcon";
import ColorPicker from "./elements/shop/ColorPicker";
import Product  from "./elements/shop/Product";
import ProductCard from "./elements/shop/ProductCard";
import PromoCard from "./elements/shop/PromoCard";


//3d modeling
import Modeling from "./elements/modeling/Modeling";
import Order from "./elements/modeling/Order";

import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";

// Blocks Layout

import Team from "./blocks/Team";
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { renderToString } from 'react-dom/server';

const helmetContext = {};

class Root extends Component {
  render() {
    return (
      <HelmetProvider context={helmetContext}>
      <BrowserRouter basename={"/"}>
        {/* <PageScrollTop> */}
          <Routes>
            {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={Demo} /> */}
            <Route
              path={`${process.env.PUBLIC_URL}/`}
              element={<MainPage/>}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/digital-subbotnik`}
              element={<DigitalSubbotnik/>}
            />

          <Route
              exact
              path={`${process.env.PUBLIC_URL}/partners`}
              element={<Partners/>}
            />
          
          <Route
              exact
              path={`${process.env.PUBLIC_URL}/rvm`}
              element={<RVM/>}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/eco-superapp`}
              element={<EcoSuperApp/>}
            />

          <Route
              exact
              path={`${process.env.PUBLIC_URL}/3d-printing`}
              element={<Printing/>}
            />
          <Route
              exact
              path={`${process.env.PUBLIC_URL}/3d-printing-street`}
              element={<PrintingStreet/>}
            />
          <Route
              exact
              path={`${process.env.PUBLIC_URL}/3d-printing-custom`}
              element={<PrintingCustom/>}
            />
          <Route
              exact
              path={`${process.env.PUBLIC_URL}/ai-in-recycling`}
              element={<AI/>}
            />
            
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/neural-network`}
              element={<NeuralNetwork/>}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/shop`}
              element={<Shop/>}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/shop/cart`}
              element={<Cart/>}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/shop/product`}
              element={<Product/>}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              element={<Contact/>}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/modeling`}
              element={<Modeling/>}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/modeling/order`}
              element={<Order/>}
            />


          </Routes>
        {/* </PageScrollTop> */}
      </BrowserRouter>
      </HelmetProvider>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
serviceWorker.register();
