export const version = "4.1.1";

export const cartStorage = "tbcartstorage";
export const userIdStorage = "tbuseridstorage";
export const ecoinBalanseStorage = "tbecoinbalansestorage";
export const nameStorage = "tbnamestorage";
export const emailStorage = "tbemailstorage";
export const phoneStorage = "tbphonestorage";
export const emailUrl =  "https://tbaf.azurewebsites.net/api/SendSalesEmail";
export const salesEmail = "info@trashback.net";
export const salesSub = "Заказ в магазине";
export const modelingSub = "Заказ в конструкторе";



export const maxEcoinPayShare = 0.5;



export const mainColor =  "#61BE78";
export const mainDarkColor =  "#438554";
export const backgroundDarkColor =  "#334251";
export const redColor =  "#cc0000";
export const textColor =  "#3D3E4D";
export const lightTextColor =  "#8a8b94";
export const lightlightTextColor =  "#f5f5f6";


export const reo1Color =  "#2DECF5";
export const reo2Color =  "#47E372";
export const blackColor =  "#000000";
export const yellowColor =  "#EOF400";
export const greyColor =  "#A1A3B5";
export const greenColor =  "#13F6AB";
export const lightGreen =  "#D1FFDE";
export const lightBlue =  "#D1FAFF";
export const blueColor =  "#426FFA";
export const pinkColor =  "#A551B7";
export const backgroundColor =  "#fff";



export const tinkoffVariables = {	
    terminal: '1567405864089',
    terminalkey : 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAv5yse9ka3ZQE0feuGtemYv3IqOlLck8zHUM7lTr0za6lXTszRSXfUO7jMb+L5C7e2QNFs+7sIX2OQJ6a+HG8kr+jwJ4tS3cVsWtd9NXpsU40PE4MeNr5RqiNXjcDxA+L4OsEm/BlyFOEOh2epGyYUd5/iO3OiQFRNicomT2saQYAeqIwuELPs1XpLk9HLx5qPbm8fRrQhjeUD5TLO8b+4yCnObe8vy/BMUwBfq+ieWADIjwWCMp2KTpMGLz48qnaD9kdrYJ0iyHqzb2mkDhdIzkim24A3lWoYitJCBrrB2xM05sm9+OdCI1f7nPNJbl5URHobSwR94IRGT7CJcUjvwIDAQAB',
    terminalPassword : 'qxfctutphr6lwijg'
  }