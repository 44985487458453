import React, { useRef, useState } from 'react'
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);
import {
  mainColor,
  mainDarkColor,
  backgroundDarkColor,
  textColor,
  lightTextColor
} from "../../../Constants"




const TitleSelection = (props) => {
  const {t} = useTranslation();
  const [itemValue, setItemValue] = useState(props.default);


  return (
          <Box>
                {props.name ? <Typography variant="body1"  sx={{fontFamily: 'Manrope'}} bold color={textColor}>{props.name}</Typography> :<></>}
                {props.description ? <Typography variant='caption'  sx={{fontFamily: 'Manrope', display: 'block' }} color={lightTextColor} gutterBottom>{props.description}</Typography> :<></>}
          </Box>
  );
};
export default TitleSelection;