import React, { useRef, useState, useEffect } from 'react'
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);
import { Routes, Route, useParams, useSearchParams } from 'react-router-dom';
import {calculatePrice} from "./service/serviceFunctions"
import CustomInput from '../../component/elements/common/CustomInput'
import CustomButton from '../../component/elements/common/CustomButton'
import axios from "axios";
import {modelParams} from "./service/modelParams"
import {
  cartStorage,
  ecoinBalanseStorage,
  userIdStorage,
  nameStorage,
  emailStorage,
  phoneStorage,
  lightlightTextColor,
  mainColor,
  emailUrl, 
  salesEmail, 
  modelingSub,
  maxEcoinPayShare
} from '../../Constants';
import OrderValue from '../../component/elements/modeling/OrderValue';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const Order = (props) => {
  const {t} = useTranslation();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [selectedProduct, setSelectTedProduct] = useState(1);
  const [itemDepth, setItemDepth] = useState(searchParams.get("itemDepth"));
  const [itemWidth, setItemWidth] = useState(searchParams.get("itemWidth"));
  const [itemHeight, setItemHeight] = useState(searchParams.get("itemHeight"));
  const [itemColor, setItemColor] = useState(searchParams.get("itemColor"));
  const [itemDoorColor, setItemDoorColor] = useState(searchParams.get("itemDoorColor"));
  const [itemPrice, setItemPrice] = useState(0);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [alertDialogIsOpen, setAlertDialogIsOpen] = useState(false);

  useEffect(() => 
  {
    updatePrice()
  },[])

  const updatePrice = () =>{
    var itemPriceCalc = calculatePrice(selectedProduct, itemDepth, itemWidth, itemHeight);
    setItemPrice(itemPriceCalc)
  }

  const completeOrder = () =>{
    if(
      name === "" ||
      phone === "" ||
      email === "" 
      )
    {
      setAlertDialogIsOpen(true);
    }else{
      sendOrder();
      setDialogIsOpen(true);
    }
  }

  const handleAlertDialogClose = () =>{
    setAlertDialogIsOpen(false);

  }

  const handleDialogClose = () =>{
    setName("");
    setEmail("");
    setPhone("");
    setDialogIsOpen(false);
    navigation.navigate('/');
  }

  const sendOrder = () => {
    let req = {
      selectedProduct,
      itemDepth,
      itemWidth, 
      itemHeight,
      itemColor, 
      itemDoorColor, 
      itemPrice, 
      name,
      phone,
      email
    };
    axios.get(emailUrl,{   
      params: {
        to: salesEmail,
        sub: modelingSub,
        name: name,
        phone: phone,
        email: email,
        request: JSON.stringify(req)
      }})
    .then((response) =>  {
      console.log(response);
    })
    .catch((error) =>  {
      console.log(error);
    });
  }

  return (
    <>
    {selectedProduct != null
      ?<Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        p={2}
        sx={{ minHeight: '100vh' }}
      >
        <Grid item xs={12} md={6} backgroundColor={lightlightTextColor} px={4} py={2} borderRadius={5} minWidth="30vw">
            <OrderValue 
              description="Модель" 
              value={modelParams.filter(p => p.id == selectedProduct)[0].name}/>
            <OrderValue 
              description="Размеры, высота*ширина*глубина (см)" 
              value={itemHeight + " *" +itemWidth + " * " +itemDepth}
              />
            <OrderValue 
              description="Цвет дверцы" 
              value={itemColor}
              isColor={true}/>
            <OrderValue 
              description="Цвет стенок" 
              value={itemDoorColor}
              isColor={true}/>
            <OrderValue 
              description="Цена, рублей" 
              value={itemPrice}/>
            <OrderValue 
              description="Доставка" 
              value="Самовывоз. Москва, Ибрагимова, 31к7"/>
            <OrderValue 
              description="Срок изготовления, рабочих дней" 
              value="5"/>
            <CustomInput 
              label="Контактное лицо"
              onChange={(e) => setName(e)}
            />
            <CustomInput 
              label="Телефон"
              onChange={(e) => setPhone(e)}
            />
            <CustomInput 
              label="Email"
              onChange={(e) => setEmail(e)}
            />
            <CustomButton text="Подтвердить" color={mainColor} textColor="white" onClick={() => completeOrder()}/>
        </Grid>

        <Dialog
              open={dialogIsOpen}
              onClose={handleDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("shop.orderDialogTitle")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t("shop.orderDialogText")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose} autoFocus>
                  {t("shop.orderDialogButton")}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={alertDialogIsOpen}
              onClose={handleAlertDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("shop.orderAlertDialogTitle")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t("shop.orderAlertDialogText")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleAlertDialogClose} autoFocus>
                  {t("shop.orderAlertDialogButton")}
                </Button>
              </DialogActions>
            </Dialog>
      </Grid>
      :<></>}
    </>
  );
};
export default Order