import React, { useRef, useState } from 'react'
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);
import {
  mainColor,
  mainDarkColor,
  backgroundDarkColor,
  redColor
} from "../../../Constants"




const PriceCalculator = (props) => {
  const {t} = useTranslation();

  return (
          <Box 
            onClick={(e) => props.onClick(id)}
            sx={{
                p: 0.5,
                mr: 1,
                my: 2,
                borderRadius:3, 
                maxWidth: 150,
                border: 2, 
                borderColor: "white",  
                backgroundColor: redColor,
                cursor: 'pointer',
                justifyContent: "center",
                display: "flex" }}>
                  <Stack direction="row">
                   <Typography  color="white">Цена
                   <Typography variant='h5' color="white">{props.value} руб.</Typography>
                   </Typography>
                   </Stack>
          </Box>
  );
};
export default PriceCalculator;