import { useEffect, useState } from "react";
import {useTranslation} from 'react-i18next';
import ProductCard from "./ProductCard";
import PromoCard from "./PromoCard";
import Grid from '@mui/material/Grid';
import {products} from '../../assets/shop/products';
import {promo} from '../../assets/shop/promo';
import CartIcon from "./CartIcon";
import {
  cartStorage,
  ecoinBalanseStorage,
  userIdStorage,
  nameStorage,
  emailStorage,
  phoneStorage
} from '../../Constants';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);
import PageHelmet from "../../component/common/Helmet";

const Shop = (props) => {
  const {t} = useTranslation();
  const [cart, setCart] = useState([]);
  const [productsSorted, setProductsSorted] = useState([]);
  const [promoSorted, setPromoSorted] = useState([]);
  const location = useLocation();

  useEffect(()=>{
    const params = new URLSearchParams(location.search);
    params.get('userId') != null &&  localStorage.setItem(userIdStorage, params.get('userId'));
    params.get('ecoinBalance') != null &&  localStorage.setItem(ecoinBalanseStorage, params.get('ecoinBalance'));
    params.get('name') != null &&  localStorage.setItem(nameStorage, params.get('name'));
    params.get('email') != null &&  localStorage.setItem(emailStorage, params.get('email'));
    params.get('phone') != null &&  localStorage.setItem(phoneStorage, params.get('phone'));
    //console.log(params.get('ecoinBalance'))
    let cart = localStorage.getItem(cartStorage);
    //console.log(JSON.parse(cart))
    try{
      const cartJSON = JSON.parse(cart);
      setCart(cartJSON);
    }
    catch
    {
      //console.log("Cart data error");
    }

    let sortedData =  products.sort((a, b) => b.priority-a.priority);
    setProductsSorted(sortedData);

    let sortedPromo =  promo.sort((a, b) => b.priority-a.priority);
    setPromoSorted(sortedPromo);

  },[])

  return (
    <>
      <PageHelmet 
                  pageTitle={t('shop.pageTitle')}
                  canonical={t('shop.canonical')}
                  description={t('shop.description')}
                  keywords={t('shop.keywords')}
                  image={t('shop.image')}
                  />
      <Grid m={{xs:1, sm:2, md:5, lg: 10}}>
      {/* <PromoCard promo={promoSorted}/> */}
      <Grid container spacing={2}>
        {productsSorted.length > 1
        ? <>
        {productsSorted.map((item) => 
            <Grid xs={12} md={3} key={item.id} item>
                <ProductCard 
                   id={item.id}
                   productName={item.productName}
                   companyName={item.companyName}
                   companyLogo={item.companyLogo}
                   images={item.images}
                   productPrice={item.productPrice}
                   productDescription={item.productDescription}
                   productParameters={item.productParameters}
                />
              </Grid>  
        )}
        </>
        :<></>}
      </Grid>
      </Grid>
      <Box 
        ml={3}
        sx={{
          position: "absolute",
          top: "40px",
          right: "40px",
        }}>
        <CartIcon 
          cart={cart}
          />
      </Box>
    </>
  );
};
export default Shop;