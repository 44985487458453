import React, { useRef, useState } from 'react'
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);
import {
  mainColor,
  mainDarkColor,
  backgroundDarkColor
} from "../../../Constants"




const SwithButton = (props) => {
  const {t} = useTranslation();
  const [id, setId] = useState(props.id);
  const [selectedId, setSelectedId] = useState(props.selectedId);


  return (
          <Box 
            onClick={(e) => props.onClick(id)}
            sx={{
                p: 1,
                mr: 1,
                mt: 2,
                borderRadius:3, 
                borderColor: props.selectedId != props.id ?  "white" : mainColor,  
                backgroundColor: mainColor,
                cursor: 'pointer',
                justifyContent: "center",
                display: "flex" }}>
                   <Typography color="white">{props.name}</Typography>
          </Box>
  );
};
export default SwithButton;