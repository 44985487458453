import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import { FiAward, FiCheck, FiGrid, FiShoppingCart, FiCalendar , FiDollarSign, FiMap, FiImage, FiCoffee, FiSmartphone, FiThumbsUp, FiBarChart ,FiMapPin,  FiHeart ,FiGift, FiChevronUp, FiTrash } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import phoneBlank from "../assets/images/about/phoneBlank.png";
import AppStore from "../elements/AppStore";
import { useTranslation, withTranslation, Trans } from 'react-i18next';

import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);

class EcoSuperApp extends Component{
    render(){
      const { t } = this.props;
      const ServiceList = [
        {
            icon: <FiSmartphone />,
            title: t('ecoApp.bullit1'), 
            description: t('ecoApp.bullit1Description'), 
        },
        {
            icon: <FiGrid />,
            title: t('ecoApp.bullit2'), 
            description: t('ecoApp.bullit2Description'), 
        },
        {
            icon: <FiImage />,
            title: t('ecoApp.bullit3'), 
            description: t('ecoApp.bullit3Description'), 
        },
        {
            icon: <FiGift />,
            
            title: t('ecoApp.bullit4'), 
            description: t('ecoApp.bullit4Description'), 
        },
        {
            icon: <FiShoppingCart />,
            title: t('ecoApp.bullit5'), 
            description: t('ecoApp.bullit5Description'), 
        },
        {
            icon: <FiDollarSign />,
            title: t('ecoApp.bullit6'), 
            description: t('ecoApp.bullit6Description'), 
        }
    ]
    var techList = [
      t('ecoApp.techList1'), 
      t('ecoApp.techList2'), 
      t('ecoApp.techList3'), 
      t('ecoApp.techList4'), 
      t('ecoApp.techList5')
      ];
        return(
            <React.Fragment>
                <PageHelmet 
                  pageTitle={t('ecoApp.pageTitle')}
                  description={t('ecoApp.description')}
                  keywords={t('ecoApp.keywords')}
                  image={t('ecoApp.image')}
                  />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={t('ecoApp.title')}   />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>{t('ecoApp.title1')}</h2>
                                    <p>{t('ecoApp.description1')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>

                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                 {/* About us  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div >
                    <img
                      src={phoneBlank}
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{t('ecoApp.techListTitle')}</h2>
                      <p className="description">{t('ecoApp.techListDescription')}</p>
                      <div className="mt--30">
                    <ul className="list-style--1">
                      {techList.map((name, index) => {
                        return (
                          <li key={index}>
                            <FiCheck /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About us  */}


    {/* Start Brand Area */}
    <div className="rn-brand-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <p>{t('ecoApp.markets')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--40">
                                <AppStore branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

             
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}

export default withTranslation()(EcoSuperApp);