export const products = [
    {
        "id": "stul",
        "priority" : 159,
        "productName": "Стул",
        "companyName": "TrashBack",
        "companyLogo": "https://trashback.net/logo1_Horisontal.png",
        "images": [
            "https://i.ibb.co/tz6qpyT/chair5.png",
            "https://i.ibb.co/NTpgmBV/chair4.png",
            "https://i.ibb.co/YksqK1H/chair1.png",
            "https://i.ibb.co/pWf6NYz/chair3.png",
            "https://i.ibb.co/pdGkZV3/chair2.png"
        ],
        "productPrice" : 19000,
        "productDescription" : "Стул на металлическом каркасе, напечатанный с помощью крупноформатного 3D принтера из вторичного пластика. Поверхность глянцевая.",
        "productParameters": {
            "weight" : 8,
            "height" : 80,
            "width" : 50,
            "depth": 51,
            "material" : "06-PS",
            "recyclableMaterials" : 97.5
        },
        "colors":[
            "#E0218A",
            "#F6DC7C",
            "#A2F69A",
            "#B2DBF8",
            "#F4BCBC"
        ]
    },
    {
        "id": "stol",
        "priority" : 152,
        "productName": "Стол журнальный",
        "companyName": "TrashBack",
        "companyLogo": "https://trashback.net/logo1_Horisontal.png",
        "images": [
            "https://i.ibb.co/yFYprxV/sTable2.png",
            "https://i.ibb.co/25RY4c0/sTable3.png",
            "https://i.ibb.co/pLW0FQ3/sTable1.png"
        ],
        "productPrice" : 12000,
        "productDescription" : "Журнальный стол, напечатанный с помощью крупноформатного 3D принтера из вторичного пластика. Поверхность глянцевая.",
        "productParameters": {
            "weight" : 8,
            "height" : 80,
            "width" : 50,
            "depth": 51,
            "material" : "06-PS",
            "recyclableMaterials" : 97.5
        },
        "colors":[
            "#E0218A",
            "#F6DC7C",
            "#A2F69A",
            "#B2DBF8",
            "#F4BCBC"
        ]
    },
 
    {
        "id": "tumba",
        "priority" : 141,
        "productName": "Прикроватная тумба",
        "companyName": "TrashBack",
        "companyLogo":"https://trashback.net/logo1_Horisontal.png",
        "images": [
            "https://i.ibb.co/fNc7R6f/tumba5.png",
            "https://i.ibb.co/XYxcgFg/tumba1.png",
            "https://i.ibb.co/3hmc95c/tumba3.png",
            "https://i.ibb.co/cJ9T3xX/tumba2.png" 
        ],
        "productPrice" : 13000,
        "productDescription" : "Прикроватная тумба, напечатанная на 3D принтере из вторичного пластика",
        "productParameters": {
            "weight" : 8,
            "height" : 53,
            "width" : 32,
            "depth": 22,
            "material" : "06-PS",
            "recyclableMaterials" : 97.5
        },
        "colors":[
            "#E0218A",
            "#F6DC7C",
            "#A2F69A",
            "#B2DBF8",
            "#F4BCBC"
        ]
    },
    {
        "id": "vase",
        "priority" : 51,
        "productName": "Ваза настольная",
        "companyName": "TrashBack",
        "companyLogo": "https://trashback.net/logo1_Horisontal.png",
        "images": [
            "https://i.ibb.co/SXvjtxt/vase1.png",
            "https://i.ibb.co/BnkCDnd/vase2.png",
            "https://i.ibb.co/RQkzhyC/photo-5242381311803508287-y-1.jpg",
            "https://i.ibb.co/xg4Spb4/photo-5242381311803508284-y.jpg",  
        ],
        "productPrice" : 3500,
        "productDescription" : "Ваза настольная, корпус которой напечатан на 3D принтере из вторичного пластика или двересно-полимерного композита",
        "productParameters": {
            "weight" : 1,
            "height" : 30,
            "width" : 20,
            "depth": 20,
            "material" : "06-PS/WPS",
            "recyclableMaterials" : 97.5
        },
        "colors":[
            "#E0218A",
            "#F6DC7C",
            "#A2F69A",
            "#B2DBF8",
            "#F4BCBC"
        ]
    },
    {
        "id": "chandelier",
        "priority" : 49,
        "productName": "Люстра",
        "companyName": "TrashBack",
        "companyLogo": "https://trashback.net/logo1_Horisontal.png",
        "images": [
            "https://i.ibb.co/W6kgsCz/chan3.png",
            "https://i.ibb.co/B4s7Q40/chan4.png",
            "https://i.ibb.co/f2ftcSS/chan2.png",
            "https://i.ibb.co/HHyVMDS/Chan1.png"
        ],
        "productPrice" : 5000,
        "productDescription" : "Люстра, корпус которой напечатан на 3D принтере",
        "productParameters": {
            "weight" : 1,
            "height" : 30,
            "width" : 20,
            "depth": 20,
            "material" : "06-PS",
            "recyclableMaterials" : 15
        },
        "colors":[
            "#E0218A",
            "#F6DC7C",
            "#A2F69A",
            "#B2DBF8",
            "#F4BCBC"
        ]
    },
    {
        "id": "lamp",
        "priority" : 47,
        "productName": "Настольная лампа",
        "companyName": "TrashBack",
        "companyLogo": "https://trashback.net/logo1_Horisontal.png",
        "images": [
            "https://i.ibb.co/TvTntCQ/lamp1.png",
            "https://i.ibb.co/jgn0r8v/lamp3.png",
            "https://i.ibb.co/nkTW6gd/lamp2.png" 
        ],
        "productPrice" : 5000,
        "productDescription" : "Настольная лампа, корпус которой напечатан на 3D принтере",
        "productParameters": {
            "weight" : 1,
            "height" : 27,
            "width" : 20,
            "depth": 20,
            "material" : "06-PS",
            "recyclableMaterials" : 15
        },
        "colors":[
            "#EDB273&"
        ]
    }
]