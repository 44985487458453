import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import { FiAward , FiCalendar , FiMap, FiImage, FiCoffee, FiSmartphone, FiThumbsUp, FiBarChart ,FiMapPin,  FiHeart ,FiGift, FiChevronUp, FiTrash } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Testimonial from "../elements/Testimonial";
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);
import { useTranslation, withTranslation, Trans } from 'react-i18next';


class DigitalSubbotnik extends Component{
    render(){
        const { t } = this.props;
        const ServiceList = [
            {
                icon: <FiCalendar />,
                title: t('digitalSubbotnik.bullit1'),
                description: t('digitalSubbotnik.bullit1Description')
            },
            {
                icon: <FiSmartphone />,
                title: t('digitalSubbotnik.bullit2'),
                description: t('digitalSubbotnik.bullit2Description')
            },
            {
                icon: <FiAward />,
                title: t('digitalSubbotnik.bullit3'),
                description: t('digitalSubbotnik.bullit3Description')
            },
            {
                icon: <FiGift />,
                title: t('digitalSubbotnik.bullit4'),
                description: t('digitalSubbotnik.bullit4Description')
            },
            {
                icon: <FiBarChart />,
                title: t('digitalSubbotnik.bullit5'),
                description: t('digitalSubbotnik.bullit5Description')            
            },
            {
                icon: <FiHeart />,
                title: t('digitalSubbotnik.bullit6'),
                description: t('digitalSubbotnik.bullit6Description')                    
            }
        ]
        
        const ActionList = [
            {
                icon: <FiMap />,
                title: t('digitalSubbotnik.action1'),
                description: t('digitalSubbotnik.action1Description')
            },
            {
                icon: <FiImage />,
                title: t('digitalSubbotnik.action2'),
                description: t('digitalSubbotnik.action2Description')
            },
            {
                icon: <FiMapPin />,
                title: t('digitalSubbotnik.action3'),
                description: t('digitalSubbotnik.action3Description')
            },
            {
                icon: <FiThumbsUp />,
                title: t('digitalSubbotnik.action4'),
                description: t('digitalSubbotnik.action4Description')
            },
            {
                icon: <FiCoffee />,
                title: t('digitalSubbotnik.action5'),
                description: t('digitalSubbotnik.action5Description')
            },
            {
                icon: <FiTrash />,
                title: t('digitalSubbotnik.action6'),
                description: t('digitalSubbotnik.action6Description')
            }
        ]
        return(
            <React.Fragment>
                <PageHelmet 
                  pageTitle={t('digitalSubbotnik.pageTitle')}
                  description={t('digitalSubbotnik.description')}
                  keywords={t('digitalSubbotnik.keywords')}
                  image={t('digitalSubbotnik.image')}
                  />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={t('digitalSubbotnik.title')}   />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>{t('digitalSubbotnik.title1')}</h2>
                                    <p>{t('digitalSubbotnik.description1')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>

                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>{t('digitalSubbotnik.title2')}</h2>
                                    <p>{t('digitalSubbotnik.description2')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ActionList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" >
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--5 ptb--120">
                <div className="container">
                    <Testimonial />
                </div>
                </div>
                {/* End Testimonial Area */}
             
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default withTranslation()(DigitalSubbotnik);