import React, { useRef, useLayoutEffect, useEffect } from 'react'
import { useGLTF } from '@react-three/drei'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { useLoader } from '@react-three/fiber'
import * as THREE from 'three';
import floor from "../../../assets/sceneObjModel/floor.obj";

export const SceneFloor = (props) => {
  //https://www.trashback.net/sceneObjModel/person.obj
  const floor = useLoader(OBJLoader, 'https://raw.githubusercontent.com/maslovay/publicFiles/refs/heads/main/sceneObjModel/floor.obj')
  const wall = useLoader(OBJLoader, 'https://raw.githubusercontent.com/maslovay/publicFiles/refs/heads/main/sceneObjModel/wall.obj')
  const person = useLoader(OBJLoader, "https://raw.githubusercontent.com/maslovay/publicFiles/refs/heads/main/sceneObjModel/person.obj")
  const colorMap = useLoader(TextureLoader, "https://raw.githubusercontent.com/maslovay/publicFiles/refs/heads/main/sceneObjModel/WoodFloorAsh.jpg")
  colorMap.wrapS = THREE.RepeatWrapping;
  colorMap.wrapT = THREE.RepeatWrapping;
  colorMap.repeat.set(400 , 100);
  useLayoutEffect(() => {
    colorMap.repeat.set(0.001,0.001)
    floor.traverse(child => {
      if (child.isMesh) {
        child.material.map = colorMap
      }
    })
    wall.traverse(child => {
      if (child.isMesh) {
        child.material.color.set("#E6EDF8")
      }
    })
    person.traverse(child => {
      if (child.isMesh) {
        child.material.color.set("#5d4f4d")
      }
    })
  }, [props])


  return (
  //   <mesh>
  //   <sphereGeometry args={[1, 32, 32]} />
  //   <meshStandardMaterial map={colorMap} />
  // </mesh>
    <mesh scale={[1,1,1]}>
      {/* <boxGeometry args={[100, 100, 100]} /> */}
      <primitive object={wall} {...props} scale={[1,1,1]} />
      <primitive 
        object={person} 
        {...props} 
        scale={[1,1,1]} 
        position={[-500, 680, 250]}
        rotation={[Math.PI / -2, 0, 0]}/>
      <primitive object={floor} {...props} scale={[1,1,1]} />
    </mesh>
  )
}
