import React, { useRef, useState } from 'react'
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  mainColor,
  mainDarkColor
} from "../../../Constants"


const PrettoSlider = styled(Slider)({
  color: mainColor,
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const CustomSlider = (props) => {
  const {t} = useTranslation();
  const [itemValue, setItemValue] = useState(props.default);

  const updateValue = (value) => {
    setItemValue(value);
    return `${value}${props.measure}`;
  }


  return (
          <Box pt={1}>
            <Typography gutterBottom sx={{fontFamily: 'Manrope'}}> 
              {props.label}
            </Typography>
            <Grid container>
                <Grid xs={9}>
                  <PrettoSlider  
                    defaultValue={props.default} 
                    aria-label={props.label}
                    getAriaValueText={updateValue}
                    min={props.min}
                    max={props.max}
                    valueLabelDisplay="auto"
                    onChange={(e) => {props.onUpdate(e.target.value)}}
                    />
                </Grid>
                <Grid xs={3} sx={{textAlign: "right"}}>
                    <Typography variant="h4" display="inline"  sx={{fontFamily: 'Manrope'}}>
                        {itemValue} 
                        <Typography variant="subtitle1" display="inline"  sx={{fontFamily: 'Manrope'}}>
                            {props.measure} 
                        </Typography>
                    </Typography>
                </Grid>
              </Grid>
          </Box>
  );
};
export default CustomSlider;