import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import { FiAward, FiCheck , FiCalendar , FiMap, FiImage, FiCoffee, FiSmartphone, FiThumbsUp, FiBarChart ,FiMapPin,  FiHeart ,FiGift, FiChevronUp, FiTrash } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import our_printer from "../assets/images/portfolio/our_printer.png";
import aiImage from "../assets/images/fundomat_3recognitions.png";
import wpc_3dprint from "../assets/images/portfolio/wpc_3dprint.png";
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);
import { useTranslation, withTranslation, Trans } from 'react-i18next';

class AI extends Component{
    render(){
      const { t } = this.props;
        return(
            <React.Fragment>
                                <PageHelmet 
                  pageTitle={t('ai.pageTitle')}
                  canonical={t('ai.canonical')}
                  description={t('ai.description')}
                  keywords={t('ai.keywords')}
                  image={t('ai.image')}
                  />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={t('ai.title')}   />
                {/* End Breadcrump Area */}

               
                 {/* Start About Area  */}
      <div className="rn-about-area ptb--50 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{t('ai.title1')}</h2>
                    <p className="description">{t('ai.description1')}</p>
                  </div>
                  
                </div>
              </div>

              <div className="col-lg-6 mt_md--40 mt_sm--40">
                <div className="thumbnail">
                  <iframe width="560" height="315" src={t('ai.link1')} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}


      <div className="rn-about-area ptb--50 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
            <div className="col-lg-6 mt_md--40 mt_sm--40">
                <div className="thumbnail">
                  <img className="w-100" src={aiImage} alt="Trashback printer" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{t('ai.title2')}</h2>
                    <p className="description">{t('ai.description2')}</p>
                  </div>
                  
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}


                       {/* Start About Area  */}
                       <div className="rn-about-area ptb--50 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{t('ai.title3')}</h2>
                    <p className="description">{t('ai.description3')}</p>
                  </div>
                  
                </div>
              </div>

              <div className="col-lg-6 mt_md--40 mt_sm--40">
                <div className="thumbnail">
                <iframe width="560" height="315" src={t('ai.link3')} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default withTranslation()(AI);