import React, { useRef, useState } from 'react'
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);
import {
  mainColor,
  mainDarkColor,
  backgroundDarkColor
} from "../../../Constants"




const CustomButton = (props) => {
  const {t} = useTranslation();

  return (
          <Box 
            onClick={(e) => props.onClick()}
            sx={{
                p: 2,
                my: 2,
                borderRadius:3, 
                backgroundColor: props.color,
                cursor: 'pointer',
                justifyContent: "center",
                display: "flex" }}>
                   <Typography color={props.textColor}>{props.text}</Typography>
          </Box>
  );
};
export default CustomButton;