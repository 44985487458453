import React, { useRef, useState } from 'react'
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);
import {
  mainColor,
  mainDarkColor,
  backgroundDarkColor,
  textColor
} from "../../../Constants"
import { styled } from '@mui/material/styles';

const CustomTextField = styled(TextField)({
    '& label': {
      color: textColor,

    },
    '& label.Mui-focused': {
      color: mainDarkColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: mainDarkColor,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: textColor
      },
      '&:hover fieldset': {
        borderColor: textColor
      },
      '&.Mui-focused fieldset': {
        borderColor: mainDarkColor,

      },
    },
  });

const CustomInput = (props) => {
  const {t} = useTranslation();
  const [value, setValue] = useState(props.value)


  return (
    <Box my={2}>
       <CustomTextField 
          label={props.label} 
          defaultValue={props.value} 
          value={value}
          onChange={(e) => {props.onChange(e.target.value), setValue(e.target.value) }}
          fullWidth />
    </Box>
  );
};
export default CustomInput;