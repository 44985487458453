import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import { FiCheck , FiCalendar , FiMap, FiImage, FiCoffee, FiSmartphone, FiThumbsUp, FiBarChart ,FiMapPin,  FiHeart ,FiGift, FiChevronUp, FiTrash } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import cocaColaRvm from "../assets/images/cocaColaRVM.png";
import PortfolioRvm from "../component/HomeLayout/homeOne/PortfolioRvm";
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);
import { useTranslation, withTranslation, Trans } from 'react-i18next';

class RVM extends Component{
    
    render(){
      const { t } = this.props;
        var techList = [
          t('rvm.techList1'),
          t('rvm.techList2'),
          t('rvm.techList3'),
          t('rvm.techList4'),
          t('rvm.techList5'),
          t('rvm.techList6'),
          t('rvm.techList7'),
          t('rvm.techList8'),
          ];
        return(
            <React.Fragment>
                <PageHelmet 
                  pageTitle={t('rvm.pageTitle')}
                  canonical={t('rvm.canonical')}
                  description={t('rvm.description')}
                  keywords={t('rvm.keywords')}
                  image={t('rvm.image')}
                  />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={t('rvm.title')}   />
                {/* End Breadcrump Area */}

                 {/* Start About Area  */}
      <div className="rn-about-area ptb--120 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{t('rvm.title1')} </h2>
                    <p className="description">{t('rvm.description1')} </p>
                    <ul className="list-style--1">
                      {techList.map((name, index) => {
                        return (
                          <li key={index}>
                            <FiCheck /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  
                </div>
              </div>

              <div className="col-lg-5 mt_md--40 mt_sm--40">
                <div className="thumbnail">
                  <img className="w-100" src={cocaColaRvm} alt="About Images" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}

        {/* Start Portfolio Area */}
         <div className="portfolio-area ptb--120 bg_color--1">
              <div className="portfolio-sacousel-inner mb--55">
                <PortfolioRvm />
              </div>
            </div>
         {/* End Portfolio Area */}


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default withTranslation()(RVM);