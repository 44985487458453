import React, { useRef, useState, useEffect } from 'react'
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import CustomSlider from '../../component/elements/common/CustomSlider'
import ColorVariant from '../../component/elements/modeling/ColorVariant'
import SwithButton from '../../component/elements/common/SwithButton'
import PriceCalculator from '../../component/elements/modeling/PriceCalculator'
import TitleSelection from '../../component/elements/modeling/TitleSelection'
import CustomButton from '../../component/elements/common/CustomButton'

import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
ReactGA.initialize(' G-T1ZQZDYLTJ');
ReactGA.pageview(window.location.pathname + window.location.search);
import { createRoot } from 'react-dom/client'
import {modelParams} from "./service/modelParams"
import {calculatePrice} from "./service/serviceFunctions"
import {LockerObj} from "./objects/LockerObj"
import {LockerGeometry} from "./objects/LockerGeometry"

import {SceneFloor} from "./scene/SceneFloor"
import { backgroundColor, mainColor } from '../../Constants';
import { colors, Stack } from '@mui/material';
const measure = "см";
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Fisheye, CameraControls, PerspectiveCamera, Environment,Sky, OrbitControls } from '@react-three/drei'
import { MeshWobbleMaterial, useGLTF } from '@react-three/drei'
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Html, useProgress } from '@react-three/drei'
import PageHelmet from "../../component/common/Helmet";

const Modeling = (props) => {
  const {t} = useTranslation();
  const location = useLocation();
  const meshRef = useRef()
  const cameraControlRef = useRef();
  const { progress } = useProgress()
  const [selectedProduct, setSelectTedProduct] = useState(1);
  const [itemDepth, setItemDepth] = useState(modelParams.filter(p => p.id == selectedProduct)[0].params.defaultItemDepth);
  const [itemWidth, setItemWidth] = useState(modelParams.filter(p => p.id == selectedProduct)[0].params.defaultItemWidth);
  const [itemHeight, setItemHeight] = useState(modelParams.filter(p => p.id == selectedProduct)[0].params.defaultItemHeight);
  const [itemColor, setItemColor] = useState(modelParams.filter(p => p.id == selectedProduct)[0].params.defaultItemColor);
  const [itemDoorColor, setItemDoorColor] = useState(modelParams.filter(p => p.id == selectedProduct)[0].params.defaultItemDoorColor);
  const [itemPrice, setItemPrice] = useState(modelParams.filter(p => p.id == selectedProduct)[0].params.defaultItemPrice);
  const navigate = useNavigate();

  useEffect(() => {
    var itemPriceCalc = calculatePrice(selectedProduct, itemDepth, itemWidth, itemHeight);
    setItemPrice(itemPriceCalc)
  },[itemDepth, itemWidth, itemHeight]);



  return (<>
      <PageHelmet 
                  pageTitle={t('modeling.pageTitle')}
                  canonical={t('modeling.canonical')}
                  description={t('modeling.description')}
                  keywords={t('modeling.keywords')}
                  image={t('modeling.image')}
                  />
 
    <Grid container  sx={{ height:"100vh", width: "100vw"}}>
      
      <Grid sx={{ height: {xs: "50vh", sm: "50vh", md: "100vh"}, width: {xs: "100vw", sm: "100vw", md: "70vw"}}}>
        
        <Canvas flat  style={{ height: "100%", width: "100%"}} camera={{ position: [-5, 12, 25], fov: 50, maxZoom: 1, minZoom: 1 }}>  
          <color attach="background" args={["#d0d0d0"]} />
          <fog attach="fog" args={["#d0d0d0", 8, 155]} />
          {progress<100 && <Html center>{progress}% loaded</Html>}
          <ambientLight intensity={2.5} />
          <OrbitControls minDistance={10} maxDistance={50} />
          <group scale={0.01} position={[5, -11, -5]}>
            <LockerGeometry
                  position={[0, 0, 0]} 
                  itemDepth={itemDepth} 
                  defaultItemDepth={modelParams.filter(p => p.id == selectedProduct)[0].params.defaultItemDepth} 
                  itemWidth={itemWidth} 
                  defaultItemWidth={modelParams.filter(p => p.id == selectedProduct)[0].params.defaultItemWidth} 
                  itemHeight={itemHeight} 
                  defaultItemHeight={modelParams.filter(p => p.id == selectedProduct)[0].params.defaultItemHeight} 
                  itemColor={itemColor}
                  itemDoorColor={itemDoorColor}
              />
            <SceneFloor/>
          </group>
        </Canvas>
        <Box sx={{position: "relative", left: 0, bottom: 100, pl: 4}}>
          <PriceCalculator value={itemPrice} />
        </Box>
        <Box sx={{position: "absolute", top: 30, right: 30}}>
          <img
          src="/logo1_green.png"
          alt="TrashBack"
          loading="lazy"
          width={100}
          />
        </Box>
      </Grid>
      <Grid px={4}   sx={{height: {xs: "50vh", sm: "50vh", md: "100vh"},  overflow: 'auto', flex:1}}>
          <Box sx={{ height: '100%', mt: {xs: 0, sm: 0, md: 10}} }>
          <Grid container>
          <Grid xs={6} md={6} pt={1}>
          {modelParams.map((i, val) =>
            <SwithButton 
              key={i}
              name={i.name}
              onClick={(id) => setSelectTedProduct(id)}
              selelctedId = {selectedProduct}
              id={i.id}
              />
          )}
          </Grid>
          <Grid xs={12} pt={1}>
            <TitleSelection  
            name="Цвет дверцы"
            description="Дверца изготовлена из ЛДСП. Дверца открывается на 165%. Открытие нажатием (push-to-open)" />
          </Grid>
          {modelParams.filter(p => p.id == selectedProduct)[0].params.colorsDoor.map((i, val) =>
          <Grid xs={2}>
            <ColorVariant 
              color={i}
              selectedColor={itemDoorColor} 
              onClick={(val)=> setItemDoorColor(val)}
              
              />
          </Grid>
          )}
          <Grid xs={12} pt={1}>
          <TitleSelection  
            name="Цвет стенок"
            description="Корпус изготовлен методом крупноформатной 3D печати. Материал корпуса - переработанный ударопрочный полистирол (06-PS)" />
          </Grid>
          {modelParams.filter(p => p.id == selectedProduct)[0].params.colorsBody.map((i, val) =>
          <Grid xs={2}>
            <ColorVariant 
              color={i}
              selectedColor={itemColor} 
              onClick={(val)=> setItemColor(val)}/>
          </Grid>
          )}
          <Grid xs={12}>
            <CustomSlider
                default={modelParams.filter(p => p.id == selectedProduct)[0].params.defaultItemDepth}
                min={modelParams.filter(p => p.id == selectedProduct)[0].params.minItemDepth}
                max={modelParams.filter(p => p.id == selectedProduct)[0].params.maxItemDepth}
                label="Глубина"
                measure={measure}
                onUpdate={(value) => {setItemDepth(value)}}
            />
            <CustomSlider
                default={modelParams.filter(p => p.id == selectedProduct)[0].params.defaultItemHeight}
                min={modelParams.filter(p => p.id == selectedProduct)[0].params.minItemHeight}
                max={modelParams.filter(p => p.id == selectedProduct)[0].params.maxItemHeight}
                label="Высота"
                measure={measure}
                onUpdate={(value) => {setItemHeight(value)}}
            />
            <CustomSlider
                default={modelParams.filter(p => p.id == selectedProduct)[0].params.defaultItemWidth}
                min={modelParams.filter(p => p.id == selectedProduct)[0].params.minItemWidth}
                max={modelParams.filter(p => p.id == selectedProduct)[0].params.maxItemWidth}
                label="Ширина"
                measure={measure}
                onUpdate={(value) => {setItemWidth(value)}}
            />
            
            <CustomButton
                text="Заказать" color={mainColor} textColor="white" onClick={() => navigate({
                pathname: "order",
                search: `?${createSearchParams({
                  itemDepth,
                  itemWidth,
                  itemHeight,
                  itemColor,
                  itemDoorColor
                })}`
              })}/>
              <Box height={50}></Box>
            </Grid> 
            </Grid> 
          </Box>
      </Grid>
    </Grid>
    </>
  );
};
export default Modeling;